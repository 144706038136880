define([
    'lodash',
    'prop-types',
    'componentsCore',
    'santa-components',
    'image-client-api',
    'backgroundCommon/components/bgImage',
    'backgroundCommon/components/html5Video',
    'backgroundCommon/components/webglMedia',
    'coreUtils'
], function (
    _,
    PropTypes,
    componentsCore,
    santaComponents,
    imageClientApi,
    bgImage,
    html5Video,
    webglMedia,
    coreUtils
) {
    'use strict';

    const {mediaConsts, svgFilters, colorParser} = coreUtils;
    const {fittingTypes} = imageClientApi;
    const {
        POSTER,
        IMAGE,
        BG_IMAGE,
        VIDEO,
        WEBGL,
        IMAGE_BG_PARAMS,
        IMAGE_PARAMS,
        VIDEO_PARAMS,
        WEBGL_PARAMS,
        IFRAME_VIDEO_PARAMS
    } = mediaConsts.balataConsts;
    const descriptors = {
        image: {
            componentType: IMAGE_PARAMS.comp,
            skinPartData: {
                skin: IMAGE_PARAMS.skin,
                styleId: IMAGE_PARAMS.style
            }
        },
        bg_image: {
            componentType: IMAGE_BG_PARAMS.comp,
            skinPartData: {
                skin: IMAGE_BG_PARAMS.skin,
                styleId: IMAGE_BG_PARAMS.style
            }
        },
        poster: {
            componentType: IMAGE_PARAMS.comp,
            skinPartData: {
                skin: IMAGE_PARAMS.skin,
                styleId: IMAGE_PARAMS.style
            }
        },
        video: {
            componentType: VIDEO_PARAMS.comp,
            skinPartData: {
                skin: VIDEO_PARAMS.skin,
                styleId: VIDEO_PARAMS.style
            }

        },
        webgl: {
            componentType: WEBGL_PARAMS.comp,
            skinPartData: {
                skin: WEBGL_PARAMS.skin,
                styleId: WEBGL_PARAMS.style
            }
        },
        iframe: {
            componentType: IFRAME_VIDEO_PARAMS.comp,
            skinPartData: {
                skin: IFRAME_VIDEO_PARAMS.skin,
                styleId: IFRAME_VIDEO_PARAMS.style
            }
        }
    };

    /**
     * For tiled images we render an element with css background instead of image
     * If a filter is assigned and we need to render an SVG fallback for tiled images - render an image element
     * @param {object} props
     * @returns {boolean}
     */
    function shouldUseBgImage(props) {
        const {fittingType, cssFiltersSupported, filterEffect} = props;

        const isFilter = filterEffect && svgFilters.isFilterExists(filterEffect.effectType);
        const isTile = fittingType === fittingTypes.TILE;
        // tile && ((filter && !ie) || !filter)
        return isTile && ((isFilter && cssFiltersSupported) || !isFilter);
    }

    function getBgImageProps(props, {filterEffect}, hasBgScrollEffect) {
        return {
            ref: IMAGE,
            id: props.id + IMAGE,
            containerId: props.parentId,
            filterEffect,
            style: _.pick(props.style, ['width', 'height']),
            mediaDimensions: props.mediaDimensions,
            imageUrlPreMeasureParams: props.imageUrlPreMeasureParams,
            shouldRenderSrc: props.shouldRenderSrc,
            imageData: props.compData,
            fittingType: props.fittingType,
            alignType: props.alignType,
            getMediaFullStaticUrl: props.getMediaFullStaticUrl, //santaComponents.santaTypesDefinitions.ServiceTopology.getMediaFullStaticUrl.isRequired,
            hasBgScrollEffect,
            wixImageLayout: props.wixImageLayout,
            'data-type': IMAGE_BG_PARAMS['data-type']
        };
    }

    function getImageProps(props, {filterEffect}, hasBgScrollEffect) {
        return {
            ref: IMAGE,
            id: props.id + IMAGE,
            containerId: props.parentId,
            autoLayout: true,
            wixImageLayout: props.wixImageLayout,
            key: `img_${props.bgEffectName}`,
            style: {position: 'absolute', top: 0, width: '100%', height: '100%'},
            containerWidth: _.get(props.mediaDimensions, 'width', 0),
            containerHeight: _.get(props.mediaDimensions, 'height', 0),
            imageUrlPreMeasureParams: props.imageUrlPreMeasureParams,
            shouldRenderSrc: props.shouldRenderSrc,
            imageData: props.compData,
            filterEffect,
            displayMode: props.fittingType,
            hasBgScrollEffect,
            fittingType: props.fittingType,
            alignType: props.alignType,
            'data-type': IMAGE_PARAMS['data-type'],
            maskData: props.maskPosterFallback
        };
    }

    function getVideoProps(props, {registerMediaSource, notifyVideoVisibility, showMedia, filterEffect}, hasBgScrollEffect) {
        const value = {
            ref: VIDEO,
            id: props.id + VIDEO,
            parentId: props.parentId,
            style: _.pick(props.style, ['width', 'height']),
            key: `vid_${props.compData.videoId}`,
            notifyMediaState: props.notifyMediaState,
            isPlayingAllowed: props.isPlayingAllowed,
            isEditorMode: props.isEditorMode,
            setMediaAPI: props.setMediaAPI,
            format: props.playbackFormat,
            config: props.playbackConfig,
            playbackUrl: props.playbackUrl,
            videoRenderParts: props.renderParts.media.video,
            compProp: props.compProp,
            keepVideoHidden: _.includes(props.compData.mediaFeatures, 'alpha'),
            notifyVideoVisibility,
            posterWidth: _.get(props.mediaDimensions, 'width', 0),
            posterHeight: _.get(props.mediaDimensions, 'height', 0),
            imageUrlPreMeasureParams: props.imageUrlPreMeasureParams,
            shouldRenderSrc: props.shouldRenderSrc,
            filterEffect,
            hasBgScrollEffect,
            fittingType: props.fittingType,
            alignType: props.alignType,
            showMedia,
            wixVideoLayout: props.wixVideoLayout
        };

        if (registerMediaSource) {
            value.registerMediaSource = registerMediaSource;
        }

        return value;
    }

    function getWebglProps(props, {getMediaSource, notifyWebGLVisibility, showMedia, filterEffect, altText}) {
        return {
            ref: WEBGL,
            id: props.id + WEBGL,
            key: `gl_${props.compData.videoId}`,
            getMediaSource,
            showMedia,
            isEditorMode: props.isEditorMode,
            notifyMediaState: props.notifyMediaState,
            playerStyle: props.playerStyle,
            filterEffect,
            altText,
            notifyWebGLVisibility,
            getIsVisible: props.getIsVisible,
            addVisibilityStateListener: props.addVisibilityStateListener,
            addWebGLContext: props.addWebGLContext,
            removeWebGLContext: props.removeWebGLContext
        };
    }

    function getMediaType(props) {
        const {compData, enableVideo} = props;
        if (compData.type === 'Image') {
            return shouldUseBgImage(props) ? BG_IMAGE : IMAGE;
        }
        if (compData.type === 'WixVideo') {
            if (enableVideo) {
                if (_.includes(compData.mediaFeatures, 'alpha') && props.renderParts.media.video.length) {
                    return WEBGL;
                }
                return VIDEO;
            }
            return POSTER;
        }
    }

    function parseFilterColors(filterEffect, colorsMap) {
        if (_.isPlainObject(filterEffect)) {
            return _.mapValues(filterEffect, value => colorParser.isThemeColor(value) ? colorParser.getHexColor(colorsMap, value) : value);
        }
        return filterEffect;
    }

    /**
     * @class components.bgMedia
     * @extends {core.skinBasedComp}
     */
    return {
        displayName: 'bgMedia',
        mixins: [componentsCore.mixins.skinBasedComp, componentsCore.mixins.createChildComponentMixin],
        propTypes: _.defaults(
            {
                id: PropTypes.string.isRequired,
                compData: PropTypes.object.isRequired,
                compProp: PropTypes.object,
                parentId: PropTypes.string.isRequired,
                rootId: PropTypes.string.isRequired,
                alignType: PropTypes.string,
                fittingType: PropTypes.string,
                mediaTransforms: PropTypes.object,

                filterEffect: PropTypes.object,
                bgEffectName: PropTypes.string,
                isFullScreenHeight: PropTypes.bool,
                maskPosterFallback: PropTypes.object,

                style: PropTypes.object.isRequired,
                playerStyle: PropTypes.object,

                isPlayingAllowed: PropTypes.bool,
                isEditorMode: PropTypes.bool,
                enableVideo: PropTypes.bool,
                renderParts: PropTypes.object,
                notifyMediaState: PropTypes.func,
                setMediaAPI: PropTypes.func,
                isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen.isRequired,
                getIsVisible: PropTypes.func,
                addVisibilityStateListener: PropTypes.func,
                addWebGLContext: PropTypes.func,
                removeWebGLContext: PropTypes.func,
                wixImageLayout: PropTypes.bool,
                wixVideoLayout: PropTypes.bool,

                colorsMap: santaComponents.santaTypesDefinitions.Theme.colorsMap,
                cssFiltersSupported: santaComponents.santaTypesDefinitions.BrowserFlags.cssFiltersSupported.isRequired,
                getMediaFullStaticUrl: santaComponents.santaTypesDefinitions.ServiceTopology.getMediaFullStaticUrl.isRequired,
                renderFixedPositionBackgrounds: santaComponents.santaTypesDefinitions.RenderFlags.renderFixedPositionBackgrounds
            },
            santaComponents.utils.santaTypesUtils.getSantaTypesFromPropTypes(santaComponents.components.Image.propTypes),
            santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(bgImage),
            santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(html5Video),
            santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(webglMedia)
        ),

        getInitialState() {
            return {
                mediaSource: null,
                showMedia: false,
                showVideo: false,
                showWebGL: true
            };
        },

        /**
         * Get the Wix types of the the component to render (id, style, data, etc.)
         * @returns {object}
         */
        getMediaComponentsDescriptors() {
            const componentsDescriptors = [];
            const {compData} = this.props;
            const type = getMediaType(this.props);
            const filterEffect = parseFilterColors(this.props.filterEffect, this.props.colorsMap);
            const hasBgScrollEffect = this.props.isFullScreenHeight;

            switch (type) {
                case IMAGE: {
                    const extraProps = getImageProps(this.props, {filterEffect}, hasBgScrollEffect);
                    const imageDesc = _.assign({}, descriptors.image, {extraProps, compData});
                    componentsDescriptors.push(imageDesc);
                    break;
                }
                case BG_IMAGE: {
                    const extraProps = getBgImageProps(this.props, {filterEffect}, hasBgScrollEffect);
                    const imageDesc = _.assign({}, descriptors.bg_image, {extraProps, compData});
                    componentsDescriptors.push(imageDesc);
                    break;
                }
                case VIDEO: {
                    const localProps = {
                        showMedia: this.state.showMedia,
                        notifyVideoVisibility: this.notifyVideoVisibility,
                        filterEffect
                    };
                    const extraProps = getVideoProps(this.props, localProps, hasBgScrollEffect);
                    const videoDesc = _.assign({}, descriptors.video, {extraProps, compData});
                    componentsDescriptors.push(videoDesc);
                    break;
                }
                case WEBGL: {
                    const webglLocalProps = {
                        showMedia: this.state.showMedia,
                        notifyWebGLVisibility: this.notifyWebGLVisibility,
                        getMediaSource: this.getMediaSource,
                        filterEffect,
                        altText: compData.alt || ''
                    };
                    const webglExtraProps = getWebglProps(this.props, webglLocalProps);
                    const webglDesc = _.assign({}, descriptors.webgl, {extraProps: webglExtraProps, compData});
                    componentsDescriptors.push(webglDesc);

                    const videoLocalProps = {
                        showMedia: this.state.showMedia,
                        notifyVideoVisibility: this.notifyVideoVisibility,
                        registerMediaSource: this.registerMediaSource,
                        filterEffect
                    };
                    const videoExtraProps = getVideoProps(this.props, videoLocalProps, hasBgScrollEffect);
                    const videoDesc = _.assign({}, descriptors.video, {extraProps: videoExtraProps, compData});
                    componentsDescriptors.push(videoDesc);

                    break;
                }
                case POSTER: {
                    const extraProps = getImageProps(this.props, {filterEffect}, hasBgScrollEffect);
                    extraProps.imageData = compData.posterImageRef;
                    const posterDesc = _.assign({}, descriptors.poster, {extraProps, compData: compData.posterImageRef});
                    componentsDescriptors.push(posterDesc);
                    break;
                }
            }

            return componentsDescriptors;
        },
        /**
         * Get the component to render as media
         * @returns {ReactCompositeComponent}
         */
        getMediaComponents() {
            const componentDescriptors = this.getMediaComponentsDescriptors();
            return _.map(componentDescriptors, descriptor => {
                const {compData, componentType, skinPartData, extraProps} = descriptor;
                return this.createChildComponent(compData, componentType, skinPartData, extraProps);
            });
        },

        getMediaSource() {
            return this.state.mediaSource;
        },

        registerMediaSource(mediaSource) {
            if (mediaSource !== this.state.mediaSource) {
                this.setState({mediaSource});
            }
        },

        notifyVideoVisibility(showVideo) {
            if (showVideo !== this.state.showVideo) {
                this.setState({
                    showVideo,
                    showMedia: showVideo && this.state.showWebGL
                });
            }
        },

        notifyWebGLVisibility(showWebGL) {
            if (showWebGL !== this.state.showWebGL) {
                this.setState({
                    showWebGL,
                    showMedia: showWebGL && this.state.showVideo
                });
            }
        },

        /**
         * Change key when moving between editor and preview to unmount the media (instead of clearing animations and media info)
         * @returns {string}
         */
        getKey() {
            const video = this.props.enableVideo ? 'video' : 'no_video';
            const playback = this.props.isEditorMode ? 'no_playback' : 'playback';
            return this.props.bgEffectName ? `media_${playback}_${video}` : 'bgMedia';
        },
        getSkinProperties() {
            const children = this.getMediaComponents();
            const key = this.getKey();

            // For Animation baseClear. do not delete
            const transformDataAttrs = _.mapKeys(this.props.mediaTransforms, (value, attr) => `data-${attr}`);
            const isFullScreenByEffect = this.props.renderFixedPositionBackgrounds && this.props.isFullScreenHeight;
            return {
                '': _.assign({
                    tagName: this.props.wixImageLayout && isFullScreenByEffect ? 'wix-bg-media' : 'div',
                    key,
                    children,
                    style: this.props.style,
                    /**
                     * For QA Automation
                     * https://jira.wixpress.com/browse/SE-11582
                     * https://jira.wixpress.com/browse/SE-15126
                     * https://jira.wixpress.com/browse/CLNT-6534
                     */
                    'data-has-bg-effect': isFullScreenByEffect ? '1' : '',
                    'data-fitting': this.props.fittingType,
                    'data-align': this.props.alignType,
                    'data-container-id': this.props.parentId,
                    'data-page-id': this.props.rootId,
                    'data-media-comp-type': getMediaType(this.props)
                }, transformDataAttrs)
            };
        }
    };
});
